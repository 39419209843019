import { mapGetters, mapActions, mapMutations } from 'vuex'
import {
    NOT_AVAIBLE,
    PERSONAL_TREES,
    OPEN_SEARCH_TREE,
    LOADING_SEARCH_TREE,
    TREES_SKIP,
    TREES_SEARCH,
    PERSONAL_TREES_TYPE,
    PERSONAL_SORT_CATEGORY,
    PERSONAL_SELECT_CATEGORY,
    PERSONAL_TYPE_SEARCH,
    TREES_LIMIT,
    RESET_SEARCH_TREE,
    TREE_SEARCH_LOCATION,
    TREE_SEARCH_PURCHASE,
    TREE_SEARCH_PLANTING,
    GENERATED_CERTIFICATE,
    GET_ORDER,
    SHOW_TREES_MAP_POPUP_AREA,
    SING_DOCUMENT,
    DOCUMENT_TREE_ID,
    SHOW_POPUP_DOCUMENTS,
    DOCUMENT_TREE_ORDER_ID,
    CERTIFICATE_TYPE,
    GET_CERTIFICATE,
    CURRENT_GIFT_OBJ,
    SHOW_POPUP_GIFT_LIMIT,
    TERM_OF_USE,
    MY_TREE_LIST_FILE,
    TREE_SEARCH_STATUS

} from '@/store/mutation-types';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import PopupSearch from './components/popup-search/index.vue'
import ExpressTrees from './components/express-trees/index.vue'
import { showTextPopup } from '@/helpers/variables/index'
import store from "@/store";

export default {
    name: 'trees',
    data(){
        return{
            idOrder: null,
            page: 0,
            start: 0,
            end: 0,
            openSelect: false,
            sortList: [
                {
                    name: this.$t('sortBySeason'),
                    query: 'season'
                },
                {
                    name: this.$t('sortByLocation'),
                    query: 'location'
                }
            ],
            payload: {
                tree_id: ''
            }
        }
    },
    computed: {
        ...mapGetters({
            skip: `trees/skip`,
            loadingTreesDocument: `trees/loadingTreesDocument`,
            limit: `trees/limit`,
            lengthList: `trees/lengthList`,
            typeSearch: `trees/typeSearch`,
            sortType: `trees/sortType`,
            types: `trees/trees`,
            list: `trees/list`,
            loading: `trees/loading`,
            openSearchPopupState: `trees/openSearchPopup`,
            selectTypeCategory: `trees/selectTypeCategory`,
            sortTypeCategory: `trees/sortTypeCategory`,
            location: `trees/location`,
            purchase: `trees/purchasePeriod`,
            planting: `trees/plantingPeriod`,
            status: `trees/status`,
            currentLocation: `trees/currentLocation`,
            currentStatus: `trees/currentStatus`,
            resultSearchPlanting: `trees/resultSearchPlanting`,
            resultSearchPurchase: `trees/resultSearchPurchase`,
            resultSearchStatus: `trees/resultSearchStatus`,
            resultSearchSeason: `trees/resultSearchSeason`,
            resultSearchLocation: `trees/resultSearchLocation`,
            personalParams: `params/personalParams`,
            user: `profile/user`,
            certificateLoading: `trees/certificateLoading`,
            termOfUse: `trees/termOfUse`,
            helperContent: `helpCalculate/helperContent`,
            fetchUserData: `profile/user`,
        }),
        ...mapGetters('new_user',{
            uuid: "uuid",
        }),
        prepareList(){
            let count_tress = this.user.futures_balance / 144
            let trees = []
            for (let i = 0; i < count_tress; i++) {
                trees.push(this.getEmptyTree())
            }

            console.log(trees)
            return [
                ...this.list,
                ...trees
            ]
        },
        watchFieldsPopup(){
            if(this.resultSearchPlanting !== '' || this.resultSearchPurchase!== ''||this.resultSearchStatus!== ''||this.resultSearchLocation!== '' || this.resultSearchSeason !== ''){
                return true
            }else{
                return false
            }
        },
        showPagination(){
            if(10 > this.lengthList){
                return false
            }else{
                return true
            }
        }
    },
    components: {
        VueAdsPagination,
        PopupSearch,
        VueAdsPageButton,
        ExpressTrees
    },
    created(){
        this.search();
    },
    mounted() {
        this.fetchUser().then(() => {
            this.setUuidAsync(this.fetchUserData.uuid)
        })
    },
    updated(){
        document.addEventListener('click', () => {
            if(this.openSelect === true){
                this.openSelect = false;
            }
        });
    },
    watch: {
        page(){
            this.changeSkip(this.page * this.limit);
            this.search();
        }
    },
    methods: {
        getEmptyTree(){
          return {
              "id": 0,
              "uuid": 'GE-KA:0',
              "field_id": 0,
              "location": "Грузия",
              "cadastral": '0',
              "type": {
                  "data": {
                      "id": 1,
                      "title": "Олива"
                  }
              },
              "sale_status": {
                  "data": {
                      "id": 1,
                      "title": "На балансе"
                  }
              },
              "gift_status": {
                  "data": {
                      "id": 4,
                      "title": "Купленное"
                  }
              },
              "year": 2024,
              "age": 0,
              "season": 1,
              "purchase_date": '',
              "purchase_price": '0',
              "coordinates": {
                  "lat": 0,
                  "lng": 0
              },
              "sale_frozen_to": null,
              "dividend_frozen_to": null,
              "initial_price": '0',
              "current_price": '0',
              "signed_documents": false,
              "is_sold": true,
              "blocked": false
          }
        },
        ...mapActions('new_certificate',{
            downloadCertificateAsync: 'downloadCertificateAsync',
        }),
        ...mapActions('new_user',{
            setUuidAsync: 'setUuidAsync',
        }),
        ...mapActions({
            fetchTrees: `trees/${PERSONAL_TREES}`,
            searchTrees: `trees/${TREES_SEARCH}`,
            generatedCertificate: `trees/${GENERATED_CERTIFICATE}`,
            singDocument: `trees/${SING_DOCUMENT}`,
            loadOrderId: `trees/${GET_ORDER}`,
            getTermOfUse: `trees/${TERM_OF_USE}`,
            getCertificate: `trees/${GET_CERTIFICATE}`,
            myTreeListFyle: `trees/${MY_TREE_LIST_FILE}`,
            fetchUser: `profile/LOAD_USER_DATA`,
        }),
        ...mapMutations({
            notAvaible: `system/${NOT_AVAIBLE}`,
            openSearch: `trees/${OPEN_SEARCH_TREE}`,
            loadingSearch: `trees/${LOADING_SEARCH_TREE}`,
            resetList: `trees/${RESET_SEARCH_TREE}`,
            activeType: `trees/${PERSONAL_TREES_TYPE}`,
            sortCategory: `trees/${PERSONAL_SORT_CATEGORY}`,
            selectCategory: `trees/${PERSONAL_SELECT_CATEGORY}`,
            typeSearchStatus: `trees/${PERSONAL_TYPE_SEARCH}`,
            changeSkip: `trees/${TREES_SKIP}`,
            changeLocation: `trees/${TREE_SEARCH_LOCATION}`,
            changePurchase: `trees/${TREE_SEARCH_PURCHASE}`,
            changeStatus: `trees/${TREE_SEARCH_STATUS}`,
            changePlanting: `trees/${TREE_SEARCH_PLANTING}`,
            changePopup: `map/${SHOW_TREES_MAP_POPUP_AREA}`,
            changeTreeId: `trees/${DOCUMENT_TREE_ID}`,
            showPopupDocuments: `trees/${SHOW_POPUP_DOCUMENTS}`,
            changeTreeOrderId: `trees/${DOCUMENT_TREE_ORDER_ID}`,
            certificateType: `trees/${CERTIFICATE_TYPE}`,
            currentObjGift: `gift/${CURRENT_GIFT_OBJ}`,
            showPopupGiftLimit: `gift/${SHOW_POPUP_GIFT_LIMIT}`,
        }),
        downloadCertificate(id){
            let lang = store.state['vue-i18n-manager'].currentLanguage.translationKey;
            lang = lang === 'ru' ? 'rus' : 'eng'

            let payload = {
                id: id
            }
            let headers = {
                auth: this.uuid,
                lang: lang,
            }
            this.downloadCertificateAsync({
                payload:payload,
                headers:headers,
            });
        },
        openTextPopup(status){
            showTextPopup(status);
        },
        hasBans(elem) {
            let divined = false;
            let sale = false;
            if (elem.dividend_frozen_to && elem.dividend_frozen_to !== null) {
                divined = true
            } else {
                divined = false
            }
            if (elem.sale_frozen_to && elem.sale_frozen_to !== null) {
                sale = true
            } else {
                sale = false
            }
            if (divined || sale) {
                return true
            }
        },
        changeDocumentPopup(id){
            this.changeTreeId(id);
            this.showPopupDocuments(true);
            this.loadOrderId(id)
                .then(response => {
                    let obj = {
                        packet_type: response.packet_type,
                        packet_id: response.packet_id
                    };
                    this.getTermOfUse(obj)
                })
        },
        showGiftLimitPopup(item){
            this.currentObjGift(item);
            this.showPopupGiftLimit(true);
        },
        loadOrder(id){
            this.loadOrderId(id)
                .then(response => {
                    this.idOrder = response.packet_id;
                    let obj = {
                        packet_type: response.packet_type,
                        packet_id: response.packet_id
                    };
                    this.changeTreeOrderId(obj);
                    this.showPopupDocuments(true);
                    this.getTermOfUse(obj);
                    this.certificateType(true);
                    this.search();
                })
        },
        openSearchPopup(){
            this.openSearch(true);
        },
        search(status){
            let obj ={
                location: this.location,
                purchase_period: this.purchase,
                planting_period: this.planting,
                status: this.status,
                skip: this.skip,
                group_by: this.sortTypeCategory
            };
            this.searchTrees(obj)
                .then(() => {
                    this.typeSearchStatus(status)
                });
        },
        searchType(type){
            this.changeSkip(0);
            this.selectCategory(type.name);
            this.activeType(true);
            this.sortCategory(type.query);
            this.search();
        },
        computedCurrentSeason(val){
            if(val === 2){
                return this.$t('spring');
            }
            if(val === 4){
                return this.$t('autumn');
            }
        },
        openSelectFilter(){
            setTimeout(() => {
                this.openSelect = !this.openSelect
            }, 10)
        },
        resetFilter(){
            this.changeStatus({
                id: '',
                title: '',
                type: ''
            });
            this.changeSkip(0);
            this.selectCategory('');
            this.sortCategory('');
            this.activeType(false);
            this.search()
        },
        catchTreeListFyle(){
            this.myTreeListFyle();
        }
    },
}
